<template>
  <b-modal
    id="new-document-modal"
    :title="'Adicionar novo ' + fileType"
    hide-header
    hide-footer
    centered
    size="sm"
    @hidden="cleanModal"
  >
    <div class="modal-header d-flex flex-row justify-content-between">
      <p class="modal-title">
        {{ file && file.filename ? 'Editar ' : 'Adicionar novo ' }}
        {{ fileType }}
      </p>
      <v-close class="icon" @click="cleanModal" />
    </div>
    <div class="modal-body">
      <div class="form-group md-content">
        <label for="file">Nome do {{ fileType }}</label>
        <input
          id="file"
          class="form-control"
          placeholder="Digite o nome do arquivo..."
          v-model="fileName"
        />
      </div>
      <div class="file-atach md-content">
        <div
          class="file-container"
          :class="{ 'without-file': !this.File.name }"
          @dragover.prevent
          @drop.prevent
        >
          <div @drop="dragFile" class="drag-area">
            <div v-if="File.length <= 0">
              <p>Arraste os arquivos aqui</p>

              <div class="separator">
                <HorizontalLine /> ou <HorizontalLine />
              </div>

              <label for="add-file-input" class="add-btn"
                >Anexar arquivos</label
              >
              <input
                id="add-file-input"
                type="file"
                accept=".jpg, .png, .pdf, .jpeg, .jps, .tiff"
                @change="addFile"
              />
            </div>
            <div class="file" v-else>
              <div>
                <Pdf v-if="File.type == 'application/pdf'" />
                <Files v-else />
                {{ File.name }}
              </div>
              <v-close class="close" @click="removeFile" />
            </div>
            <b-progress
              class="progress"
              v-if="File && progress"
              :max="100"
              show-progress
              :animated="progress !== 100"
              :variant="!error ? 'info' : 'danger'"
            >
              <b-progress-bar :value="progress">
                <span
                  ><strong>{{ progress }}%</strong></span
                >
              </b-progress-bar>
            </b-progress>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          class="save-file"
          v-if="file && file.filename"
          @click="updateFile"
        >
          Editar {{ fileType }}
        </button>
        <button class="save-file" v-else @click="uploadFile">
          Salvar {{ fileType }}
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import HorizontalLine from '@/assets/icons/line.svg'
import Pdf from '@/assets/icons/pdf.svg'
import Files from '@/assets/icons/files.svg'
import Close from '@/assets/icons/close.svg'

export default {
  name: 'NewDocumentModal',
  components: {
    'v-close': Close,
    HorizontalLine,
    Pdf,
    Files
  },
  props: {
    fileType: { type: String, required: true },
    bill: Object,
    file: Object
  },

  data() {
    return {
      clinic: JSON.parse(localStorage.getItem('clinic')),
      fileName: '',
      progress: null,
      error: false,
      File: []
    }
  },

  methods: {
    onShow() {
      if (this.file && this.file.filename) {
        this.fileName = this.file.filename
      }
    },
    uploadFile() {
      if (this.File && this.fileName) {
        const dataForm = new FormData()
        dataForm.append(`file`, this.File)
        dataForm.append(`filename`, this.fileName)
        dataForm.append('clinic_id', this.clinic.id)
        dataForm.append('bill_id', this.bill.id)

        const isLoading = this.$loading.show()
        this.api
          .createBillFile(dataForm, {
            onUploadProgress: event => {
              this.progress = Math.round((event.loaded * 100) / event.total)
            }
          })
          .then(response => {
            this.$toast.success('Arquivo enviado com sucesso')
            this.error = false
            this.$emit('uploadNewDocument', response.data)
            this.$bvModal.hide('new-document-modal')
          })
          .catch(error => {
            this.$toast.error(error.message)
            this.error = true
          })
          .finally(() => {
            isLoading.hide()
          })
      } else {
        this.$toast.warning('Por favor, preencha todas informações.')
      }
    },

    updateFile() {
      if (this.File && this.fileName) {
        const formData = new FormData()
        formData.append(`file`, this.File)
        formData.append(`filename`, this.fileName)
        formData.append('clinic_id', this.clinic.id)
        formData.append('bill_id', this.bill.id)

        const isLoading = this.$loading.show()

        this.api
          .updateBillFile(this.file.id, formData, {
            onUploadProgress: event => {
              this.progress = Math.round((event.loaded * 100) / event.total)
            }
          })
          .then(response => {
            this.$toast.success('Arquivo enviado com sucesso')
            this.error = false
            this.$emit('updateNewDocument', response.data)
            this.$bvModal.hide('new-document-modal')
          })
          .catch(error => {
            this.$toast.error(error.message)
            this.error = true
          })
          .finally(() => {
            isLoading.hide()
          })
      } else {
        this.$toast.warning('Por favor, preencha todas informações.')
      }
    },

    removeFile() {
      this.progress = null
      this.error = false
      this.File = []
    },
    addFile(e) {
      this.progress = null
      this.error = false
      this.File = e.target.files[0]
    },
    dragFile(e) {
      this.progress = null
      this.error = false
      const file = e.dataTransfer.files[0];
      const fileType = file.type;
      this.fileExtension = fileType.split('/')[1];
      let nameExt = file.name.match(/(?:\.)([0-9a-z]+)$/i)
      nameExt = nameExt.length > 1 ? nameExt[1] : '';
      if (![
        'pdf','jpg','jpeg','png', 'jps',
        'application/pdf','image/jpg',
        'image/jpeg','image/png', 'tiff'
      ].includes(fileType || nameExt)) {
        return this.$toast.error('Formato de arquivo não suportado!');
      }
      this.File = e.dataTransfer.files[0]
    },
    cleanModal() {
      this.$bvModal.hide('new-document-modal')
      this.progress = null
      this.error = false
      this.fileName = ''
      this.File = []
    }
  },

  watch: {
    fileName() {
      this.fileName = this.fileName
        .replace(/\.[0-9a-z]+$/i, '') // remove extensão
        .replace(/[^\w\sA-Za-zÀ-ÖØ-öø-ÿ-]/g, ''); // remove caracteres especiais
    },
    file(newValue) {
      if (newValue) {
        this.onShow()
      }
    }
  }
}
</script>

<style lang="scss">
#new-document-modal {
  .modal-content {
    .modal-header {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px !important;

      .modal-title {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(----type-active);
      }

      .icon {
        width: 25px;
        fill: var(--neutral-500);
      }
    }
    border-radius: 8px;

    .modal-body {
      padding: 0 !important;
      margin: 0 !important;

      .title-body-content {
        color: var(--dark-blue);
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
      }

      .file-name {
        background: var(--neutral-000);
        border: 1px solid #c6ceeb;
        box-sizing: border-box;
        border-radius: 8px;
        height: 56px;
        outline: none;
        text-indent: 16px;
        color: var(--type-active);
        margin: 8px 0 24px 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
      }

      .file-inf {
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;

        span {
          font-weight: 600;
          font-size: 16px;
          line-height: 150%;
          color: var(--dark-blue);
          margin: 5px 0px;
        }

        .file-name {
          height: 38px;
          margin-bottom: 5px;
          padding: 10px;
          border: 1px solid var(--neutral-300);
          box-sizing: border-box;
          border-radius: 8px;
          font-family: Nunito Sans;
          font-style: normal;
          color: var(--type-active);
        }

        .file-name:focus {
          outline: none !important;
        }

        .file-type {
          width: 20px;
          height: 20px;
          margin-right: 5px;
          cursor: pointer;
        }
      }

      .file-atach {
        .file-container {
          height: 100px;
          background: transparent;
          border-top: 1px solid var(--neutral-200);
          border-bottom: 1px solid var(--neutral-200);

          &.without-file {
            height: 160px;
            border: 1px dashed var(--blue-500);
            box-sizing: border-box;
            border-radius: 8px;
            background: var(--neutral-100);
          }

          .drag-area {
            width: 100%;
            height: 100%;
            padding: 20px;
            font-weight: bold;
            font-size: 16px;
            line-height: 130%;
            color: var(--type-active);
            text-align: center;

            .separator {
              margin: 12px 0px;
            }

            .add-btn {
              background: var(--blue-700);
              border-radius: 8px;
              color: var(--neutral-000);
              padding: 8px 15px;
              cursor: pointer;

              font-family: Nunito Sans;
              font-size: 12px;
              font-style: normal;
              font-weight: 700;
              line-height: 16px;
              letter-spacing: 0em;
              text-align: left;
            }

            #add-file-input {
              display: none;
            }

            .file {
              display: flex;
              height: 100%;
              align-items: center;
              justify-content: space-between;
              align-content: center;

              svg {
                width: 42px !important;
                height: 42px !important;
              }

              .close {
                width: 20px !important;
                height: 20px !important;
              }
            }
          }
        }
      }

      .save-file {
        float: right;
        padding: 15px;
        margin-top: 20px;
        color: var(--neutral-000);
        background: var(--blue-500);
        border-radius: 8px;
      }
    }

    .modal-footer {
      .modal-footer-content {
        display: flex;
        justify-content: right;
        margin: 24px;

        button {
          width: auto;
          height: auto;
          padding: 14px 16px;
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          border-radius: 8px;
          background-color: var(--blue-500);
          color: var(--neutral-000);
        }
      }
    }

    .md-content {
      padding: 30px 20px 10px 20px;
    }
  }
}
</style>
